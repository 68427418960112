// action - state management
import * as actionTypes from "./actions";

export const initialState = {
  prdHeadingHierarchy: [],
  versionedPRDs: [],
  magicSelector: false,
  viewSelectedPRD: {
    selectedStep: 0,
    changed: false,
  },
  currentPrd: {},
  highlightedSection: null,
  highlightedTexts: [],
  badHighlights: [],
  basePrd: {},
  prdProjectDetails: null,
  isPrdHistoryDrawerOpen: false,
  timer: null,
  showShareLink: false,
};

// ==============================|| PRD REDUCER ||============================== //
const prdReducer = (state = initialState, action) => {
  // hack for prd wrong state update, has to get to more stable solution
  if (action?.id && action?.id !== window.location.pathname.split("/")[2]) {
    console.log(
      "skipping state update",
      action?.id,
      window.location.pathname.split("/")[2],
    );
    return state;
  }

  switch (action.type) {
    case actionTypes.SET_PRD_HEADING_HIERARCHY:
      return {
        ...state,
        prdHeadingHierarchy: action.payload,
      };

    case actionTypes.UPDATE_VERSIONED_PRD:
      console.debug("updating versioned action.versionedPRDs", action.payload);
      return {
        ...state,
        versionedPRDs: action.payload,
      };

    case actionTypes.UPDATE_MAGIC_SELECTOR:
      console.debug("updating magiv action.versionedPRDs", action.payload);
      return {
        ...state,
        magicSelector: action.payload,
      };
    case actionTypes.UPDATE_SELECTED_PRD:
      console.debug(
        "updating view versioned action.viewSelectedPRD",
        action.viewSelectedPRD,
      );
      return {
        ...state,
        viewSelectedPRD: action.viewSelectedPRD,
      };

    case actionTypes.UPDATE_CURRENT_PRD:
      console.debug("updating current PRD", action.payload);
      return {
        ...state,
        currentPrd: action.payload,
      };

    case actionTypes.UPDATE_SECTION_HIGHLIGHTED:
      console.debug("section highlighted", action.highlightedSection);
      return {
        ...state,
        highlightedSection: action.highlightedSection,
      };

    // TODO: USE IT
    case actionTypes.UPDATE_HIGHLIGHTED_TEXTS:
      console.debug("UPDATE_HIGHLIGHTED_TEXTS", action.highlightedTexts);
      return {
        ...state,
        highlightedTexts: action.highlightedTexts,
      };

    case actionTypes.UPDATE_BAD_HIGHLIGHTS:
      console.debug("UPDATE_HIGHLIGHTED_TEXTS", action.badHighlights);
      return {
        ...state,
        badHighlights: action.badHighlights,
      };

    case actionTypes.UPDATE_BASE_PRD:
      console.debug("Updating base PRD", action.payload);
      return {
        ...state,
        basePrd: action.payload,
      };

    case actionTypes.UPDATE_PRD_PROJECT_DETAILS:
      console.debug("updating prd project details to", action.payload);
      return {
        ...state,
        prdProjectDetails: action.payload,
      };

    case actionTypes.TOGGLE_IS_PRD_HISTORY_DRAWER_OPEN:
      console.debug("TOGGLE_IS_PRD_HISTORY_DRAWER_OPEN", action.payload);
      return {
        ...state,
        isPrdHistoryDrawerOpen: !state.isPrdHistoryDrawerOpen,
      };

    case actionTypes.RESET_PRD_STORE_TO_INITIAL_STATE:
      console.debug("Reseting prd store to intial state");
      return {
        ...initialState,
      };

    case actionTypes.UPDATE_SHARE_LINK_VISIBILITY:
      console.debug("updating UPDATE_SHARE_LINK_VISIBILITY");
      return {
        ...state,
        showShareLink: action.payload,
      };

    case actionTypes.UPDATE_TIMER_PRD:
      // delete old timer
      clearInterval(state.timer);
      return {
        ...state,
        timer: action.payload,
      };
    default:
      return state;
  }
};

export default prdReducer;
