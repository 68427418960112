/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
const getEnvironment = () => {
  if (typeof window !== "undefined") {
    // Client-side
    const hostname = window.location.hostname;
    if (hostname.includes("staging")) {
      return "staging";
    } else if (hostname.includes("app")) {
      return "production";
    } else {
      return "development";
    }
  } else {
    // Server-side
    const environment = process.env.NEXT_PUBLIC_ENVIRONMENT || "development";
    return environment;
  }
};

const environment = getEnvironment();

export const isStaging = environment === "staging";
export const isProduction = environment === "production";
export const isDev = environment === "development";

const getBaseUrl = () => {
  // Using Next.js environment variables
  return process.env.NEXT_PUBLIC_API_URL || getDefaultBaseUrl();
};

const getDefaultBaseUrl = () => {
  switch (environment) {
    case "production":
      return "https://app.getcreatr.xyz";
    case "staging":
      return "https://staging.getcreatr.xyz";
    default:
      return "http://localhost:4000";
  }
};

export default getBaseUrl;

