import React from "react";
import Image from "next/image";
import { cn } from "@/lib/utils";
import { Loader2 } from "lucide-react";

interface FullPageLayoutProps {
  children: React.ReactNode;
  variant?: "default" | "auth" | "split" | "centered";
  loading?: boolean;
  backgroundImage?: string;
  showLogo?: boolean;
}

interface LogoProps {
  className?: string;
}

const Logo = ({ className }: LogoProps) => (
  <div className={cn("flex items-center gap-2 text-xl font-bold", className)}>
    {/* Replace with your logo */}
    <span>Your Logo</span>
  </div>
);

const LoadingOverlay = () => (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-white/80 backdrop-blur-sm">
    <Loader2 className="h-8 w-8 animate-spin text-primary" />
  </div>
);

export const FullPageLayout = ({
  children,
  variant = "default",
  loading = false,
  backgroundImage,
  showLogo = true,
}: FullPageLayoutProps) => {
  const layouts = {
    // Default full-page layout
    default: (
      <div className="min-h-screen bg-gray-50">
        {showLogo && (
          <header className="fixed top-0 w-full border-b bg-white px-4 py-3">
            <div className="mx-auto max-w-7xl">
              <Logo />
            </div>
          </header>
        )}
        {children}
        {loading && <LoadingOverlay />}
      </div>
    ),

    // Authentication layout with side image
    auth: (
      <div className="relative min-h-screen bg-white">
        <div className="grid min-h-screen grid-cols-1 lg:grid-cols-2">
          {/* Left side - Content */}
          <div className="flex min-h-full flex-col justify-center px-4 py-12 sm:px-6 lg:px-8">
            {showLogo && <Logo className="mb-8" />}
            {children}
          </div>

          {/* Right side - Image */}
          <div className="hidden lg:block">
            <div
              className="relative h-full w-full bg-cover bg-center bg-no-repeat"
              style={{
                backgroundImage: `url(${backgroundImage || "/api/placeholder/1200/800"})`,
                backgroundColor: "rgb(17, 24, 39)",
              }}
            >
              <div className="absolute inset-0 bg-gray-900/50" />
            </div>
          </div>
        </div>
        {loading && <LoadingOverlay />}
      </div>
    ),

    // Split layout with custom background
    split: (
      <div className="relative min-h-screen bg-gray-50">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url(${backgroundImage || "/api/placeholder/1920/1080"})`,
          }}
        >
          <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" />
        </div>

        <div className="relative min-h-screen">
          {showLogo && (
            <header className="px-4 py-3">
              <Logo className="text-white" />
            </header>
          )}
          <main className="mx-auto max-w-7xl px-4 py-16">{children}</main>
        </div>
        {loading && <LoadingOverlay />}
      </div>
    ),

    // Centered layout for focused content
    centered: (
      <div className="min-h-screen bg-gray-50">
        {showLogo && (
          <header className="fixed top-0 w-full border-b bg-white px-4 py-3">
            <div className="mx-auto max-w-7xl">
              <Logo />
            </div>
          </header>
        )}
        <main className="flex min-h-screen items-center justify-center px-4">
          <div className="w-full max-w-md space-y-8">{children}</div>
        </main>
        {loading && <LoadingOverlay />}
      </div>
    ),
  };

  return layouts[variant];
};

// Export layout wrapper components for specific use cases
export const AuthLayout = ({
  children,
  ...props
}: Omit<FullPageLayoutProps, "variant">) => (
  <FullPageLayout variant="default" {...props}>
    {children}
  </FullPageLayout>
);

export const CenteredLayout = ({
  children,
  ...props
}: Omit<FullPageLayoutProps, "variant">) => (
  <FullPageLayout variant="centered" {...props}>
    {children}
  </FullPageLayout>
);

export const SplitLayout = ({
  children,
  ...props
}: Omit<FullPageLayoutProps, "variant">) => (
  <FullPageLayout variant="split" {...props}>
    {children}
  </FullPageLayout>
);
