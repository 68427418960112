// store/reducers/canvasReducer.ts
import * as actionTypes from "src/store/actions";

interface Layout {
  id: string;
  code: string;
  name: string;
}

export interface Layouts {
  componentId: string;
  componentName: string;
  layouts: Layout[];
  screenId: string;
  state: "processing" | "ready" | "error";
  _id?: string;
}

export interface TSelectedComponent {
  layerId: string;
  componentId: string;
  screenId: string;
  componentName: string;
  screenName: string;
  hidden?: boolean;
}

export interface CanvasState {
  undoStack: string[];
  redoStack: string[];
  latestState: string;
  successfulLayouts: Layouts[];
  errorLayouts: TSelectedComponent[];
  processingLayouts: TSelectedComponent[];
}

export const INITIAL_STATE: CanvasState = {
  undoStack: [],
  redoStack: [],
  latestState: "[]",
  successfulLayouts: [],
  errorLayouts: [],
  processingLayouts: [],
};

interface Action<T extends string, P = undefined> {
  type: T;
  payload: P;
}

type CanvasActions =
  | Action<typeof actionTypes.UPDATE_HISTORY, { newState: string }>
  | Action<typeof actionTypes.UPDATE_MOST_CURR_VERSION, { latestState: string }>
  | Action<typeof actionTypes.RESET_HISTORY>
  | Action<typeof actionTypes.PUSH_UNDO_STACK, { state: string }>
  | Action<typeof actionTypes.POP_UNDO_STACK>
  | Action<typeof actionTypes.PUSH_REDO_STACK, { state: string }>
  | Action<typeof actionTypes.POP_REDO_STACK>
  | Action<typeof actionTypes.INIT_LAYOUTS, Layouts[]>
  | Action<typeof actionTypes.UPDATE_SUCCESSFUL_LAYOUTS, Layouts>
  | Action<typeof actionTypes.ADD_ERROR_LAYOUTS, TSelectedComponent>
  | Action<typeof actionTypes.ADD_PROCESSING_LAYOUTS, TSelectedComponent>
  | Action<typeof actionTypes.REMOVE_PROCESSING_LAYOUT, { componentId: string }>
  | Action<typeof actionTypes.REMOVE_ERROR_LAYOUT, { componentId: string }>;

const canvasReducer = (
  state = INITIAL_STATE,
  action: CanvasActions,
): CanvasState => {
  switch (action.type) {
    case actionTypes.UPDATE_HISTORY:
      return {
        ...state,
        undoStack: [...state.undoStack, action.payload.newState],
        redoStack: [],
        latestState: action.payload.newState,
      };

    case actionTypes.UPDATE_MOST_CURR_VERSION:
      return {
        ...state,
        latestState: action.payload.latestState,
      };

    case actionTypes.RESET_HISTORY:
      return {
        ...INITIAL_STATE,
        successfulLayouts: state.successfulLayouts,
        errorLayouts: state.errorLayouts,
        processingLayouts: state.processingLayouts,
      };

    case actionTypes.PUSH_UNDO_STACK:
      return {
        ...state,
        undoStack: [...state.undoStack, action.payload.state],
      };

    case actionTypes.POP_UNDO_STACK:
      return {
        ...state,
        undoStack: state.undoStack.slice(0, -1),
      };

    case actionTypes.PUSH_REDO_STACK:
      return {
        ...state,
        redoStack: [...state.redoStack, action.payload.state],
      };

    case actionTypes.POP_REDO_STACK:
      return {
        ...state,
        redoStack: state.redoStack.slice(0, -1),
      };

    case actionTypes.INIT_LAYOUTS:
      return {
        ...state,
        successfulLayouts: action.payload || [],
        errorLayouts: [],
        processingLayouts: [],
      };

    case actionTypes.UPDATE_SUCCESSFUL_LAYOUTS:
      return {
        ...state,
        successfulLayouts: [...state.successfulLayouts, action.payload],
      };

    case actionTypes.ADD_ERROR_LAYOUTS:
      return {
        ...state,
        errorLayouts: [...state.errorLayouts, action.payload],
      };

    case actionTypes.ADD_PROCESSING_LAYOUTS:
      return {
        ...state,
        processingLayouts: [...state.processingLayouts, action.payload],
      };

    case actionTypes.REMOVE_PROCESSING_LAYOUT:
      return {
        ...state,
        processingLayouts: state.processingLayouts.filter(
          (layout) => layout.componentId !== action.payload.componentId,
        ),
      };

    case actionTypes.REMOVE_ERROR_LAYOUT:
      return {
        ...state,
        errorLayouts: state.errorLayouts.filter(
          (layout) => layout.componentId !== action.payload.componentId,
        ),
      };

    default:
      return state;
  }
};

export default canvasReducer;

